<template>
	<div class="goods_detail">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="报名详情" name="1"></el-tab-pane>
			<el-tab-pane label="操作记录" name="2"></el-tab-pane>
			<el-tab-pane label="直播数据" name="3"></el-tab-pane>
			<el-tab-pane label="备注" name="4"></el-tab-pane>
		</el-tabs>
		<div v-if="activeName === '1'">
			<e-form ref="formRef" v-on:selectChange="selectChange" class="w75" :formConfList="goodsDetailFormList" :formData="data">
				<template #5>
					<div>
						<el-tag class="cur_p mr10" closable title="" v-for="s in sku" :key="s.index">{{s.attr_name}}/￥{{s.price}}</el-tag>
<!--						<el-tag class="cur_p" type="danger" @click="$refs.dialogRef.handleOpen()">自定义报名规格</el-tag>-->
					</div>
				</template>
				<template #dj>
					<div v-if="ys">
						<el-input placeholder="定金" v-model="data.dj"></el-input>
					</div>
				</template>
				<template #8>
					<div v-if="yh">
						<el-input placeholder="优惠额度" v-model="data.yh_price"></el-input>
					</div>
				</template>
				<template #33>
					<editor ref="editorRef" />
				</template>
			</e-form>
			<div class="tc">
				<el-button type="primary" @click="confirm">保存</el-button>
			</div>
		</div>
		<div v-if="activeName === '2'">
<!--			<div class="pb30 pt20">已进行13天10小时54分钟</div>-->
			<el-timeline>
				<el-timeline-item v-for="con in logLists" :key="con.index">
					<div class="pl20 pt20 pr20 pb20 box_s">
						<div>操作：{{con.statusInfo}}</div>
						<div class="pt4 pb4" v-if="con.memo">备注：{{con.memo}}-</div>
						<div class="pt4 pb4" v-else>备注：-</div>
						<div>{{con.username}} 提交于{{con.createtime}}</div>
					</div>
				</el-timeline-item>
			</el-timeline>
		</div>
		<div v-if="activeName === '3'">
			<Empty />
		</div>
		<div v-if="activeName === '4'">
			<ul>
				<li class="flex_r f_ai_c pl10 pr10 pt6 pb6 bg_default mb10">
					<span class="fw_b pr20">留言/备注</span>
					<el-input class="w_150 ml10 mr10"></el-input>
					<el-button type="primary">留言</el-button>
				</li>
				<li class="flex_r f_ai_c f_jc_b pl10 pr10 pt6 pb6 bg_default mb10">
					<div>
						<span class="fw_b pr20">发表留言/备注</span>
						<span class="el-icon-upload cr_999 cur_p">添加附件</span>
						<el-divider direction="vertical"></el-divider>
						<span class="el-icon-picture cr_999 cur_p">发表图片</span>
						<span>（每个附件不超过10M，每张图片不超过3M）</span>
					</div>
					<el-button class="fr" type="primary">发布</el-button>
				</li>
				<el-input type="textarea" :rows="10"></el-input>
			</ul>
			<!-- <Empty /> -->
		</div>
		<e-dialog title="报名规格" ref="dialogRef">
			<div>
				<el-form label-width="auto">
					<el-form-item label="规格">
						<el-input></el-input>
					</el-form-item>
					<el-form-item label="价格">
						<el-input></el-input>
					</el-form-item>
				</el-form>
			</div>
		</e-dialog>
	</div>
</template>

<script>
import {goodsDetailFormList} from '@data/goods'
export default {
	name: 'GoodsDetail',
	data() {
		return {
			activeName: "1",
			goodsDetailFormList,
			data: {},
			yh: false,
			ys:false,
			logLists: [],
			id: '',
			sku: []
		}
	},
	methods: {
		confirm(){
			let that = this
			let formData = this.$refs.formRef.formData
			this.post('?server=live.entr.goods.edit.push.handle.post',{
				id:this.id,
				yx_rate:formData.yx_rate,
				jikou:formData.jikou,
				ys:formData.ys,
				ys_dj:formData.dj,
				sku:formData.sku,
				sc_day: formData.sc_day,
				is_day: formData.is_day,
				send_time: formData.send_time,
				bby: formData.bby,
				kd: formData.kd,
				yh_type: formData.yh_type,
				lj: formData.yh_price,
				lq_lj: formData.yh_price,
				kf_lj: formData.yh_price,
				tw_yh: formData.tw_yh,
				th_yh: formData.th_yh,
				zp: formData.zp,
				zp_send_type: formData.zp_send_type,
				zbj_cj: formData.zbj_cj,
				yh_link: formData.yh_link,
				rate_link: formData.rate_link,
				content: this.$refs.editorRef.content,
				memo: formData.memo,
				service: formData.service_fee,
				bzj: formData.deposit,
				rate: formData.rate,
				kf: formData.kf,
				bfj:formData.bfj}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$refs.dialogRef.handleClose()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		selectChange(e){
			let that = this
			switch (e.item.prop) {
				case 'ys':
					switch (e.value) {
						case 1:
							that.ys = true
							break;
						case 2:
							that.ys = false
							break;
						default :
							that.ys = false
							break;
					}
					break;
				case 'yh_type':
					switch (e.value) {
						case 1:
							that.yh = false
							break;
						case 2:
							that.yh = true
							break;
						case 4:
							that.yh = true
							break;
						case 3:
							that.yh = true
							break;
						default :
							that.yh = false
							break;
					}
					break;
			}

		},
		handleClick() {
			switch (this.activeName) {
				case '1':
					this.GetDetail()
					break;
				case '2':
					this.GetLogDetail()
					break;
				case '4':
					break;
				case '3':
					break;
			}
		},
		GetLogDetail(){
			let that = this
			this.logLists = []
			// console.log(that.dataOrigin)
			this.get('?server=live.console.log.by.id.get',{
				id:this.id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)
				let len = response.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.logLists.push(response.data[i])
				}
				// that.dataOrigin.data = response.data.data
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetDetail(){
			let that = this
			this.get('?server=live.goods.item.info.get',{id:this.id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.data = response.data.goods
				that.sku = response.data.sku
				switch (response.data.goods.yh_type) {
					case 1:
						break;
					case 2:
						that.data.yh_price = response.data.goods.lj
						that.yh = true
						break;
					case 4:
						that.data.yh_price = response.data.goods.kf_lj
						that.yh = true
						break;
					case 3:
						that.data.yh_price = response.data.goods.lq_lj
						that.yh = true
						break;
				}
				switch (response.data.goods.ys) {
					case 1:
						that.data.dj = response.data.goods.dj
						that.ys = true
						break;
					case 2:

						break;
				}
				that.$refs.formRef.formData = response.data.goods
				that.$refs.editorRef.content = response.data.goods.content
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.id = this.$route.query.id

		if(!this.id){
			this.$message.error('请求非法')
		} else {
			this.GetDetail()
		}
	},
	beforeRouteEnter(to, from, next) {
		// 添加背景色
		document.querySelector('body').setAttribute('style', 'background-color:#f3f4f9')
		next()
	},
	beforeRouteLeave(to, from, next) {
		// 去除背景色
		document.querySelector('body').setAttribute('style', '')
		next()
	}
}
</script>

<style lang="scss" scoped>
.goods_detail {
	width: 1270px;
	margin: 60px auto;
	background: #ffffff;
	padding: 30px;
	border-radius: 8px;
}
</style>